import dynamic from 'next/dynamic';
import React from 'react'
import Faqs from '@/components/Faqs'
import Blogs from '@/components/Blogs/blogs'
import { GetStaticProps} from 'next'
import {getHomePageData } from '../lib/home';
import {convenienceOfOutsourcing } from '../lib/comman';
import {getInsightInboxData } from '../lib/insightsInbox';
import {getRecentPosts } from '../lib/post';
import {getSeoData } from '../lib/seo';
import {getTestimonialsData  } from '../lib/customerReview';
import { getRecentServices } from '../lib/services';
import {getRecentOnePost  } from '../lib/post';


const About = dynamic(() => import('@/components/About/aboutHome'));
const Billboard = dynamic(() => import('@/components/Billboard/Billboard'));
const Client = dynamic(() => import('@/components/Client'));
const Services = dynamic(() => import('@/components/Services/services'));
const Testimonial = dynamic(() => import('@/components/Testimonial'));
const WhyExcel = dynamic(() => import('@/components/WhyXcel'));
const ConvenienceOfOutsourcing = dynamic(() => import('@/components/Common/ConvenienceOfOutsourcing'));
const SEO = dynamic(() => import('@/components/Seo'));


 function Home({homeData, cosData, testimonials,initialSeo, latestPost, recentServices, insightInbox, blogsThree}:any) {

  const billbord = homeData?.home?.billboard;
  const about = homeData?.home?.about;
  const services = homeData?.home?.services;
  const happyClients = homeData?.home?.happyClients;
  const precisionAndProcess = homeData?.home?.precisionAndProcess;
  const clientTestimonials = homeData?.home?.clientTestimonials;
  const insightsDevxcel = homeData?.home?.insightsDevxcel;


  const faqs = homeData?.home?.faqs;

  return (
   <>
   
    <SEO initialSeo={initialSeo} />
  
    <Billboard billbord={billbord}/>
    <About about={about}/>
    <Services  services={services} recentServices={recentServices}/>
    <Client happyClients={happyClients} />
    <WhyExcel precisionAndProcess={precisionAndProcess}/>
    <Testimonial clientTestimonials={clientTestimonials} testimonials={testimonials} />
    <Blogs insightInbox={insightInbox}  insightsDevxcel={insightsDevxcel} latestThree={blogsThree}/>
    <ConvenienceOfOutsourcing cosData={cosData} />
    <Faqs faqs={faqs}/>
    </>
  )
}
export const getStaticProps: GetStaticProps = async () => {
  const homeData = await getHomePageData() 
  const cosData = await convenienceOfOutsourcing()
  const testimonials = await getTestimonialsData()
  const slug = 'home';
  const initialSeo = await getSeoData(slug);
  const latestPost = await getRecentOnePost();
  const insightInbox = await getInsightInboxData();
  const blogsThree = await getRecentPosts();
  const recentServices = await getRecentServices();

  return {
      props: {homeData, cosData, testimonials,initialSeo,latestPost, recentServices, insightInbox, blogsThree},
      revalidate: 10,
  }
}

export default Home;