import React from 'react'
import Image from 'next/image'
import Link from 'next/link';

import dynamic from 'next/dynamic';

const CommanBtn = dynamic(() => import('../Common/CommanBtn'));
const SignUpForm = dynamic(() => import('./SignUpForm'));

function Blogs({ insightsDevxcel, insightInbox, latestThree}:any) {

  return (
    <section className="sections p-0">
    <div className="container">
        {/* <div className="sections_title">
            <h2 data-aos="fade" data-aos-delay="100">{insightsDevxcel?.title}</h2>
            <p data-aos="fade" data-aos-delay="200" dangerouslySetInnerHTML={{ __html: insightsDevxcel?.description }}></p>
        </div> */}
        {/* <div className="row">
            
               
            <div className="col-lg-6" data-aos="fade" data-aos-delay="100">
                <div className="blog_box">
                    <Link href={`/blogs/${latestThree?.edges[0]?.node?.slug}}`} className="fit_img">
                        <Image src={latestThree?.edges[0]?.node?.featuredImage?.node?.sourceUrl} width={570} height={410} alt='blog' loading="lazy"/>
                    </Link>
                    <div className="blog_text">
                        <h3><Link href={`/blogs/${latestThree?.edges[0]?.node?.slug}}`}>{latestThree?.edges[0]?.node?.title}</Link></h3>
                        <div className="home_blog_excerpt" dangerouslySetInnerHTML={{ __html: latestThree?.edges[0]?.node?.excerpt || '' }}></div>
                    </div>
                    <div className="btn_holder">
                        <CommanBtn href={`/blogs/${latestThree?.edges[0]?.node?.slug}}`} className='' text='Explore Our Blogs' />
                    </div>
                </div>
            </div>
         
            <div className="col-lg-6 two_rows" data-aos="fade" data-aos-delay="200">
                <div className="blog_box hz">
                    <Link href={`/blogs/${latestThree?.edges[1]?.node?.slug}}`} className="fit_img">
                        <Image src={latestThree?.edges[1]?.node?.featuredImage?.node?.sourceUrl} width={260} height={325} alt='blog' loading="lazy"/>
                    </Link>
                    <div className="blog_text">
                        <h3><Link href={`/blogs/${latestThree?.edges[1]?.node?.slug}}`}>{latestThree?.edges[1]?.node?.title}</Link></h3>
                        <div className='home_blog_excerpt' dangerouslySetInnerHTML={{ __html: latestThree?.edges[1]?.node?.excerpt || '' }}></div>
                        <div className="btn_holder">
                            <CommanBtn href={`/blogs/${latestThree?.edges[1]?.node?.slug}}`} className='' text='READ MORE' />
                        </div>
                    </div>
                </div>
                <div className="blog_box hz">
                    <Link href={`/blogs/${latestThree?.edges[2]?.node?.slug}}`} className="fit_img">
                        <Image src={latestThree?.edges[2]?.node?.featuredImage?.node?.sourceUrl} width={260} height={325} alt='blog' loading="lazy"/>
                    </Link>
                    <div className="blog_text">
                        <h3><Link href={`/blogs/${latestThree?.edges[2]?.node?.slug}}`}>{latestThree?.edges[2]?.node?.title}</Link></h3>
                        <div className='home_blog_excerpt' dangerouslySetInnerHTML={{ __html: latestThree?.edges[2]?.node?.excerpt || '' }}></div>
                        <div className="btn_holder">
                            <CommanBtn href={`/blogs/${latestThree?.edges[2]?.node?.slug}}`} className='' text='VIEW MORE' />
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="cta_box signup_form white d-block gd_bg" data-aos="fade" data-aos-delay="100">
            <Image className="x_shape" src="images/x-shape.svg" width={230} height={305} alt="x shape" />
            <div className="cta_left">
                <h3>{insightInbox?.title}</h3>
                <p>{insightInbox?.description}</p>
            </div>
            <div className="cta_bottom">
                <SignUpForm/>
            </div>
        </div>
    </div>
</section>
  )
}

export default Blogs
