import React from 'react'
import dynamic from 'next/dynamic';

const CommanBtn = dynamic(() => import('../Common/CommanBtn'));

function Faqs({faqs}:any) {

  return (
    <section className="sections">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <h2 data-aos="fade" data-aos-delay="100">{faqs?.title}</h2>
                    <p data-aos="fade" data-aos-delay="200">
                        {faqs?.description}
                    </p>
                    <div className="btn_holder" data-aos="fade" data-aos-delay="300">    
                        <CommanBtn href= {`${faqs?.button?.url}`} className='' text={faqs?.button?.title} />
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="accordion" id="accordionExample">
                        {
                            faqs?.faqs && faqs?.faqs.map((faq: any, index: any) => {
                                return (
                                <div key={index} className={`accordion-item${index === 0 ? ' show' : ''}`}>
                                    <h2 className="accordion-header" id={`faqHeader${index}`}>
                                        <button className={`accordion-button${index === 0 ? '' : ' collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#faq${index}`} aria-expanded={index === 0 ? 'true' : 'false'} aria-controls={`faq${index}`}>
                                            {faq?.question}
                                        </button>
                                    </h2>
                                    <div id={`faq${index}`} className={`accordion-collapse collapse${index === 0 ? ' show' : ''}`} aria-labelledby={`faqHeader${index}`} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>{faq?.answer}</p>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Faqs
